import { FetcherWithComponents, useFetcher } from '@remix-run/react';

import { FetcherSubmitOptions, SubmitTarget } from 'react-router-dom/dist/dom';

export function usePostWithIntent<T>(
  fetcher?: FetcherWithComponents<T>,
  defaultOptions?: FetcherSubmitOptions,
) {
  const defaultFetcher = useFetcher<unknown>();
  const actualFetcher = fetcher || defaultFetcher;

  return (intent: string, target?: Record<string, SubmitTarget>, options?: FetcherSubmitOptions) =>
    actualFetcher.submit({ intent, ...target }, { method: 'post', ...defaultOptions, ...options });
}
